@import '../resources.scss';

.socialnav {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7em;

    @include media-gte-sm {
        font-size: 1.4em;
    }

    @include media-gte-xs {
        font-size: 1em;
    }

    i {
        margin: 0 10px;
        color: rgba($color: #ffffff, $alpha: .5);
        transition: all .2s ease-in-out;

        @include media-gte-sm {
            margin: 0 8px;
        }

        &:hover {
            color: #fff;
        }
    }
}