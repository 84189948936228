.bandsintown {
    text-align: center;

    #tourtracker {
        
        .bit-top-track-button {
            padding: 5px 10px 0 10px;
        }
        
        .bit-nav-bar {
            margin-top: 20px;
            justify-content: center;

            .bit-logo-container {
                display: none;
            }
        }

        .bit-past-events {
            margin: 0;

            .bit-event {
                border-top: none;

                .bit-venue {
                    text-align: left;
                }
            }
        }

        .bit-widget.bit-layout-desktop .bit-event:hover {
            background-color: rgba($color: black, $alpha: .1);
            transition: all .3s ease-in-out;
            
            .bit-details {
                color: inherit;
            }
        }

        .bit-no-dates-container {
            border-top: none;

            .bit-track-button {
                max-width: 90%;
            }
        }
    }
}