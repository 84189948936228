@import "../resources.scss";

.subnav {
    width: 100%;
    margin: 0 auto 50px auto;
    display: flex;
    justify-content: center;
    font-size: 1.3em;
    font-weight: bold;

    @include media-gte-sm {
        font-size: 1.2em;
    }

    @include media-gte-xs {
        font-size: 1.1em;
    }

    ul {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 50%;
        list-style-type: none;

        @include media-gte-md {
            width: 100%;
        }

        .subnav-link {
            text-transform: capitalize;
            color: var(--text-color);
            transition: all .3s ease-in-out;
            cursor: pointer;
            text-shadow: 2px 2px 8px rgba(0,0,0,.9),
            3px 3px 5px rgba(0,0,0,.9);
            
            &:hover {
                color: var(--active-color);
            }
        }

        .subnav-active {
            color: var(--active-color);
            cursor: default;
        }
    }
}