@import "../resources.scss";

.merch {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto 15px auto;
    width: 90%;

    .merch-module {
        position: relative;
        box-sizing: border-box;
        display: inline-block;
        width: 100%;
        max-width: 300px;
        min-height: auto;
        margin: 15px 30px;
        overflow: hidden;

        @include media-gte-md {
            margin: 15px 15px;
        }

        @include media-gte-sm {
            margin: 15px 0;
        }

        h3 {
            padding-left: 0;
        }

        img.merchitem {
            display: inline-block;
            padding-top: 7%;
            padding-bottom: 15%;
            width: 100%;
            max-width: 300px;
        }
    }
}