@import "../resources";

.modal {
    display: none;

    .modal-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        cursor: pointer;
        z-index: 10;
        background-color: rgba(0,0,0,.6);
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-content {
            position: relative;
            z-index: 5;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: default;

            .modal-image {
                background-color: #F9F9F9;
                padding: 20px;
                max-height: 550px;
                max-width: 90vw;
                border-radius: 5px;
                opacity: 0;
                animation: fadeIn .5s forwards ease-in-out;
        
                @keyframes fadeIn {
                0% {
                    opacity: 0;
                }
        
                100% {
                    opacity: 1;
                }
                }
            }

            .modal-close {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: -10px;
                right: -10px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                border: 2px solid white;
                background-color: black;
                z-index: 15;
                font-size: 14pt;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: bold;
                cursor: pointer;
                box-shadow: -1px 1px 5px rgba(0,0,0,.9);
            }
        
            .modal-caption {
                margin: 5px auto 0 auto;
                padding: 2px 20px;
                font-size: .6em;
                font-family: Arial, Helvetica, sans-serif;
                background-color: black;
                border-radius: 15px;
                line-height: 24px;
            }
        
            .left-caret,
            .right-caret {
                position: absolute;
                display: flex;
                align-items: center;
                height: 100%;
                max-height: 550px;
                width: 120px;
                top: 0;
                bottom: 33px;
                margin: auto 0;
                z-index: 15;
                cursor: pointer;

                @include media-gte-sm {
                    width: 60px;
                }

                span {
                    display: none;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    border: 2px solid white;
                    background-color: rgba(0,0,0,.6);
                    box-shadow: -1px 1px 5px rgba(0,0,0,.9);
                    font-size: 14pt;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                }

                .show-caret {
                    display: flex;
                }
            }
        
            .left-caret {
                left: 0;
                justify-content: flex-start;
                margin-left: 30px;
            
                span {
                    padding-right: 2px;
                }

            }

            .right-caret {
                right: 0;
                justify-content: flex-end;
                margin-right: 30px;

                span {
                    padding-left: 2px;
                }
            }
        }
    }
}

.modal-show {
    display: block;
}