@import "../resources.scss";

.photos {
    margin: 0 auto 10px auto;

    h2 {
        margin: 10px 0;
    
        @include media-gte-sm {
            text-align: center;
            padding-left: 0;
            padding-right: 0;     
        }
    }

    .photo-row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 10px auto 40px auto;
    }
}