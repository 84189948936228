.video-container {
    margin: 20px auto;
    padding: 0 5%;
    position: relative;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  /* 16x9 Aspect Ratio */
  .video-container-16x9 {
    padding-bottom: 56.25%;
  }
  
  /* 4x3 Aspect Ratio */
  .video-container-4x3 {
    padding-bottom: 75%;
  }