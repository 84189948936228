@import '../resources.scss';

.nav,
.scroll-nav {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    will-change: transform;
    z-index: 10;
    padding: 30px 0;

    ul {
        font-size: 1.6em;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        list-style-type: none;
        margin: 0 auto;

        .nav-link {
            text-transform: capitalize;

            @include media-gte-md {
                margin: 10px 0;
            }

            a {
                color: var(--text-color);
                text-decoration: none;
                font-weight: bold;
                transition: all .3s ease-in-out;

                &:hover {
                    color: var(--active-color);
                }
            } 
        }

        .nav-active {
            a {
                color: var(--active-color);
                cursor: default;
            }
        }

    }
}

.nav {
    position: static;

    @include media-gte-md {
        top: 0;
        position: absolute;
        width: 275px;
        transform: translateX(-225px);
        transition: all .7s ease-in-out;
        padding: 0;
        z-index: 12;
    }

    h1 {
        display: none;
    }
    
    ul {
        position: static;

        @include media-gte-lg {
            font-size: 1.2em;
        }
        
        @include media-gte-md {
            position: sticky;
            top: 50px;
            flex-direction: column;
            justify-content: flex-start;
            width: 200px;
            font-size: 1em;
            padding: 0 20px 0 20px;
            margin: 0 10px;
        }
    }
}

.scroll-nav {
    position: sticky;
    top: 0;
    background-color: var(--link-color);
    animation: scrollNav .3s forwards ease-in-out;

    @include media-gte-md {
        display: none;
        position: static;
    }

    h1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        position: absolute;
        left: 20px;
        top: 0;
    }

    ul {
        font-size: 1em;
        justify-content: flex-end;

        @include media-gte-lg {
            font-size: .9em;
        }

        li {
            margin: 0 20px;

            @include media-gte-lg {
                margin: 0 10px;
            }
        }
    }

    @keyframes scrollNav {
        0% {
            transform: translateY(-80px);
        }

        100% {
            transform: translateY(0);
        }
    }
}

.nav-toggled {
    display: block;

    @include media-gte-md {
        transform: translateX(-10px);
        z-index: 12;
        background-color: rgba($color: #000000, $alpha: .8);
    }

}