@import '../resources.scss';

.hamburger {
    position: sticky;
    display: none;
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin: 10px;
    z-index: 12;
    left: 235px;
    animation: fadeIn 1.6s forwards ease-in-out;

    @include media-gte-md {
        display: block;
    }

    @include media-gte-xs {
        height: 20px;
        width: 20px;
    }

    .bar {
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: var(--active-color);
        transition: all 1s ease-in-out;

        @include media-gte-xs {
            height: 3px;
        }
    }

    .middle-bar,
    .hidden-bar {
        top: 0;
        bottom: 0;
        margin: auto 0;
    }
}

.toggled-on {
    
    .top-bar,
    .bottom-bar {
        display: none;
        opacity: 0;
    }

    .middle-bar {
        transform: rotate(-45deg);
    }

    .hidden-bar {
        transform: rotate(45deg);
    }
}

.toggled-off {

    .top-bar {
        top: 2px;
    }

    .bottom-bar {
        bottom: 2px;
    }

    .top-bar,
    .bottom-bar {
        animation: fadeIn 1.5s forwards ease-in-out;
        display: block;

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }

    .middle-bar,
    .hidden-bar {
        transform: rotate(0);
    }

}