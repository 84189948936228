@import "../resources";

.music {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto 15px auto;
    width: 90%;
}