@import "../resources.scss";

.videos {
    max-width: 800px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;

    h2 {

        @include media-gte-sm {
            text-align: center;
            padding-left: 0;
            padding-right: 0;     
        }
        
    }
}