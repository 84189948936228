@import "../resources";

.music-module {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    max-width: 300px;
    min-height: auto;
    margin: 15px 30px;
    overflow: hidden;

    @include media-gte-md {
        margin: 15px 15px;
    }

    @include media-gte-sm {
        margin: 15px 0;
    }

    iframe {
        border: 0;
        padding: 20px 0;
        margin-bottom: 1%;
        width: 100%;
        max-width: 300px;
        min-width: 1px;
        height: 480px;
    }

}