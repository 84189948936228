@import '../resources.scss';

.header {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    padding-bottom: 10px;
    position: relative;

    @include media-gte-md {
        position: sticky;
        top: 0;
        z-index: 10;
        background-image: url('../images/texture_bg.png')
    }

    img {
        width: 100%;
        max-width: 1200px;
    
        @include media-gte-md {
            width: 85%;
        }

        @include media-gte-sm {
            width: 80%;
        }
    }
    
}