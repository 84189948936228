@import "../resources.scss";

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    margin: 0 auto;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        font-size: 1em;
        text-align: center;

        @include media-gte-sm {
            font-size: .9em;
        }

        @include media-gte-xs {
            font-size: .7em;
        }
    }

}