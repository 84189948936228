@import "../resources.scss";

.photo-container {
    width: 280px;
    height: 280px;
    position: relative;
    margin: 10px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-gte-sm {
        
    }

    img {
        max-width: 800px;
        max-height: 800px;
        transform: scale(.6,.6);
    }
}