@font-face {
  font-family: 'stone';
  src: url('./fonts/stone_informal_regular-webfont.eot');
  src: url('./fonts/stone_informal_regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/stone_informal_regular-webfont.woff2') format('woff2'),
       url('./fonts/stone_informal_regular-webfont.woff') format('woff'),
       url('./fonts/stone_informal_regular-webfont.ttf') format('truetype'),
       url('./fonts/stone_informal_regular-webfont.svg#stone_informalregular') format('svg');
}

@import './resources.scss';

/* ROOT AND APP STYLES */

#root {
  height: 100%;
  position: relative;
}

.App {
  height: 100%;
  position: relative;
}

/* BASIC STYLES */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;


  @include media-gte-md {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
  }

}

:root {
  --text-color: white;
  --link-color: #A3372B;
  --active-color: #cc9e4c;
  --dark-color: black;
}

html {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url('./images/texture_bg.png');

  body {
    height: 100%;
    position: relative;
    font-family: 'stone', Arial, sans-serif;
    font-size: 16pt;
    color: var(--text-color);
    
    &.modal-open {
      overflow: hidden;
    }

    strong {
      font-weight: bold;
    }

    a {
      color: var(--link-color);
      text-shadow: 2px 2px 8px rgba(0,0,0,.9),
      3px 3px 5px rgba(0,0,0,.9);
      font-weight: bold;
      text-decoration: none;
      transition: all .3s ease-in-out;

      &:hover {
        color: var(--active-color);
      }

    }

    p {
      font-size: 1em;
      padding: 5px 0;
      line-height: 30pt;

      @include media-gte-sm {
        font-size: .9em;
        line-height: 24pt;
      }
    }

    h1 {
      font-weight: bold;
      text-shadow: 2px 2px 5px rgba(0,0,0,.9);
    }
  
    h2 {
        font-size: 1.7em;
        font-weight: bold;
        padding: 25px 0 0 15px;

        @include media-gte-sm {
          font-size: 1.5em;
        }

        @include media-gte-xs {
          font-size: 1.2em;
        }

        &.mobile-title {
          text-align: center;
          padding: 0 0 30px 0;
          display: none;

          @include media-gte-md {
            display: block;
          }

        }
    }
  
    h3 {
        font-weight: bold;
        font-size: 1.5em;
        color: var(--dark-color);
        padding: 25px 0 0 15px;
        text-shadow: 1px 1px 8px rgba(255,255,255,.7),
        2px 2px 5px black;

        @include media-gte-sm {
          font-size: 1.3em;
        }

        @include media-gte-xs {
          font-size: 1.1em;
        }
    }

    .center {
      text-align: center;
    }

    .float-left {
      float: left;
    }

    .large-font {
      font-size: xx-large;
    }

    img {

      &.text {
        box-sizing: border-box;
        padding: 30px 20px 0 0;
        max-width: 50%;
        width: 100%;

        @include media-gte-md {
          max-width: 100%;
          padding: 20px 0;
        }
      }

      &.center {
        display: block;
        margin: 0 auto;
      }
  
      &.half {
        max-width: 300px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      
      &.splash {
        max-width: 600px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      &.img-shadow {
        filter: drop-shadow(2px 2px 10px rgba(255,255,255,.5));

        @include media-gte-sm {
          filter: drop-shadow(2px 2px 5px rgba(255,255,255,.5));
        }
      }

    }
  }
}

