.press {
    
    h2,
    h3 {
        padding-left: 0;
        line-height: 30pt;
    }

    .source-date {
        line-height: 24pt;

        span {
            font-size: smaller;
        }
    }
    
}