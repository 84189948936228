@mixin media-gte-xs {
    @media screen and (max-width: 385px) {
      @content;
    }
  }
  
  @mixin media-gte-sm {
    @media screen and (max-width: 510px) {
      @content;
    }
  }
  
  @mixin media-gte-md {
    @media screen and (max-width: 798px) {
      @content;
    }
  }

  @mixin media-gte-lg {
    @media screen and (max-width: 992px) {
      @content;
    }
  }